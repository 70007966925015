.section-map {
  padding-top: pxr(40);
}

.section-map .container {
  position: relative;
}

.map-container {
  display: flex;
}

.buy-container {
  height: pxr(700);
  position: relative;
}

.map {
  height: 100%;
}

.map-heading {
  font-size: pxr(70);
  padding-bottom: pxr(60);
}

.map-buttons {
  display: flex;
  position: absolute;
  bottom: -25px;
}

.map-button {
  display: flex;
  align-items: center;
  justify-content: center;
  text-align: center;
  border: 1px solid #e6e6e7;
  padding: pxr(5) pxr(30);
  z-index: 10;
  background: white;
  font-size: pxr(16);
  color: #231f20;
  text-decoration: none;
  width: pxr(130);
  overflow: hidden;
  cursor: pointer;
}

.map-button.active {
  background: var(--main-color);
  cursor: default;
  color: white;
}

.map-button:first-child {
  border-radius: 10px 0 0 10px;
}

.map-button:last-child {
  border-radius: 0 10px 10px 0;
}

.ymaps-2-1-79-b-cluster-accordion__item-number {
  background-image: url("../icons/point-green.svg") !important;
  width: pxr(14) !important;
  height: pxr(14) !important;
  background-size: contain !important;
}

.ymaps-2-1-79-b-cluster-accordion__item-caption {
  font-weight: 500;
  font-size: pxr(16);
  transition: $transition;
}

.ymaps-2-1-79-b-cluster-accordion__item-caption:hover {
  color: var(--main-color) !important;
}

.ymaps-2-1-79-b-cluster-accordion__item-body a {
  color: var(--main-color);
  transition: $transition;
  font-size: pxr(16);
}

.ymaps-2-1-79-balloon {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__layout {
  border-radius: 20px;
  padding: pxr(20);
}

.ymaps-2-1-79-balloon__tail {
  box-shadow: none !important;
}

.ymaps-2-1-79-balloon__close-button {
  background-image: url("../icons/close.svg") !important;
}

.ymaps-2-1-79-balloon__content a {
  color: var(--main-color);
}

.shops-list {
  background-color: #E6E6E7;
  height: 100%;
  color: black;
  padding: pxr(60) 0;
  overflow-y: auto;

  & > .container {
    height: 100%;
  }
}

.shops-list-data {
  @include lists;
  align-content: space-between;
  justify-content: space-between;
  display: flex;
  flex-wrap: wrap;
  gap: 2rem;
  padding: 1rem 0 3rem 0;
  height: 100%;

  a {
    color: #1D1D1B;
    text-decoration: underline;
    font-size: pxr(20);
    transition: $transition;

    &:hover {
      opacity: 0.8;
    }
  }

  li {
    width: 40%;
  }
}


@media (max-width: $tablet-sm) {
  .map-heading {
    font-size: 5rem;
    padding-bottom: 5rem;
  }
}


@media (max-width: $mobile) {
  .buy-container {
    height: 50rem;
  }

  .shops-list-data {
    height: unset;

    li {
      width: 100%;
    }
  }
}