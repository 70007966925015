.dye {
  display: grid;
  grid-template-areas: "a a" "b c" "d c";
  margin-top: 7.7rem;
}

.dye-header {
  color: var(--text-dye-header);
  font-size: 4rem;
  font-weight: 600;
  line-height: 3rem;
  margin-bottom: 3.5rem;

  grid-area: a;
}

.dye-description {
  color: var(--text-btn-color);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3rem;
  margin-bottom: 4rem;
  max-width: 73rem;

  grid-area: b;
}

.dye-palette {
  .dye-text-img {
    position: absolute;
    top: 10%;
  }
}

.dye-text-img {
  @include center-absolute;
  max-width: pxr(280);
  max-height: 37.3rem;
}

.dye-img {
  width: pxr(444);
  display: flex;
  align-items: center;
  flex-direction: column;
  grid-area: c;
  position: relative;
  z-index: 1;
}

.dye-buttons {
  display: flex;
  gap: 3rem;
}

.btn-text {
  color: var(--text-main-color-additional);
  font-size: 2rem;
  font-style: normal;
  font-weight: 400;
  line-height: 3.8rem;
  padding: 20px 50px;
  height: 7.7rem;
  grid-area: d;

  margin-top: 4rem;

  span {
    width: 1.7rem;
    flex-shrink: 1;
    height: 1.5rem;
    background-image: url("../icons/button/arrow.svg");
    margin-right: 1.5rem;
  }
}

.dye-gloss {
  display: block;
  font-weight: bold;
  margin-top: 2rem;
  font-size: 2rem;
  color: #515e6f;
}

.dye-palette-background {
  min-height: pxr(253);
  width: 100%;
  z-index: -1;
  border-radius: 10px;
  background-size: cover;
  margin-top: auto;
  transition: 0.3s all;
}

.glide-section {
  margin: 0 auto;
  width: $container-width;
}

.glide-section .glide__slides {
  height: 110px;
}

// Кнопки переключения красок
.buttons-dye {
  background: linear-gradient(180deg, rgba(227,232,236,1) 78px, rgba(0,212,255,0) 78px);
  display: flex;
  gap: 0;
  position: sticky;
  top: -1px;
  z-index: 5;
}

.buttons-dye .composition-img {
  display: none;
}

.buttons-dye .btn-dye {
  padding: 2rem;
  height: 78px;
  display: flex;
  align-items: flex-start;
  justify-content: center;
  transition: 0.3s ease-in-out;

  .btn-dye-text {
    margin: 0;
  }
}

.btn-dye {
  text-decoration: none;
  display: flex;
  flex-direction: column;
  flex-shrink: 0;
  border: none;
  background: var(--btn-main-color-additional);
  color: var(--text-btn-color);

  font-size: 1.5rem;
  font-weight: 600;
  line-height: 1;
  text-align: left;

  height: 21.6rem;
  width: 100%;

  padding: 2rem;
  position: relative;

  &:before {
    content: "";
    background: transparent;
    width: 3rem;
    height: 3rem;
    transform: rotate(45deg);
    bottom: -1.5rem;
    position: absolute;
    z-index: -1;
    transition: 0.3s ease-in-out;
  }
}

.glide__slide--active .btn-dye {
  background: var(--main-color);
  color: var(--text-main-color-additional);

  &:before {
    background: var(--main-color);
    box-shadow: 4px 4px 8px 0px rgba(34, 60, 80, 0.2);
  }
}

.btn-dye-text {
  max-width: 18.5rem;
  margin-top: auto;
}

.dye-palette-name {
  position: absolute;
  color: #515e6f;
  background: white;
  padding: 0.5rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
  font-size: 2rem;
  bottom: 1rem;
  left: 1rem;
}

@media (max-width: $tablet-sm) {
  .dye-header {
    display: flex;
    flex-direction: column;
  }

  .dye-text-img {
    max-width: 16rem;
  }

  .dye-img {
    margin-left: 5rem;
    width: 16rem;
  }

  .btn-text {
    padding: 1rem;
    line-height: 1.5rem;
    min-width: 12rem;
  }

  .dye-palette-background {
    min-height: 18rem;
    width: 130%;
  }

  .dye-palette-name {
    font-size: 1.5rem;
    left: 0.5rem;
  }

  .dye-palette .dye-text-img {
    top: 30%;
  }

  .btn-text {
    height: 6rem;
  }
}

@media (max-width: $mobile) {
  .dye {
    display: flex;
    flex-direction: column;
  }

  .dye-img {
    width: 100%;
    margin: 0 auto 4rem auto;
  }

  .dye-palette .dye-img {
    margin-bottom: 18rem;
  }

  .dye-header {
    font-size: 3rem;
    margin-bottom: 2.5rem;
  }

  .dye-header span {
    font-size: 2rem;
  }

  .dye-description {
    margin-bottom: 3.5rem;
  }

  .dye-buttons {
    flex-direction: column;
    gap: 1.5rem;
  }

  .btn-text {
    max-width: 100%;
    margin-top: 0;
  }

  .dye-gloss {
    margin-bottom: 0;
  }

  .dye-palette-background {
    width: 100%;
  }

  .dye-palette {
    margin-bottom: 4rem !important;
  }

  .dye-palette-name {
    top: 0.5rem;
    bottom: unset;
  }
}
