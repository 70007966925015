:root {
  --main-color-additional: #fff;
  --main-color: #007872;

  --background-color-additional: #E3E8EC;

  --text-main-color-additional: #fff;
  --text-main-color: #007872;
  --text-btn-color: #515e6f;
  --text-main-color-card: #004544;
  --text-dye-header: #003837;

  --btn-main-color: #007872;
  --btn-main-color-hover: #007872;
  --btn-main-color-additional: #e3e8ec;

  --input-text-color: #878787;
  --success-color: green;
  --error-color: red;
}

$percent_rem: 0.625;
$px_rem: $percent_rem * 16;
$section-item-desktop-h: 44.5rem;
$background-linear-black: linear-gradient(to bottom, rgb(0, 0, 0, 0.7), transparent, rgba(0, 0, 0, 0));
$transition: 0.3s ease-in-out;

$container-width: 111rem;
$container-width-tablet-sm: 67rem;
$container-width-mobile: 100%;

//breakpoints
$laptop-lg: 122rem;
$laptop-sm: 110rem;
$tablet-lg: 88rem;
$tablet-sm: 73rem;
$mobile: 58rem;
