.palette {
  @include lists;
  @include flex-center;
  flex-wrap: wrap;
  gap: 2rem;
  background: #E3E8EC;
  border-radius: 10px;
  padding: 3rem 2.5rem;
  width: pxr(640);
  position: relative;
  height: pxr(253);

  &:after {
    content: '';
    display: block;
    width: 3rem;
    height: 3rem;
    background: #E3E8EC;
    position: absolute;
    right: -1.5rem;
    top: 44%;
    transform: rotate(45deg);
  }
}

.palette-item {
  width: 8rem;
  height: 8rem;
  position: relative;
  z-index: 1;

  &:hover .palette-text {
    display: inline-block;
  }

  &.active::before {
    top: -0.5rem;
    left: -0.5rem;
    bottom: -0.5rem;
    right: -0.5rem;
    content: '';
    position: absolute;
    width: 9rem;
    height: 9rem;
    z-index: -1;
    background: white;
    border-radius: 10px;
    box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  }
}

.palette-color {
  width: 100%;
  height: 100%;
  border-radius: 10px;
  background-size: cover;
  cursor: pointer;

}

.palette-text {
  display: none;
  position: absolute;
  top: -4rem;
  font-size: 2rem;
  color: #515E6F;
  background: white;
  padding: 0.5rem;
  box-shadow: 0px 4px 10px 0px rgba(0, 0, 0, 0.15);
  border-radius: 5px;
}


  [data-color="tone-colorless"] {
    background-image: url("../img/palette/tone-colorless.jpg");
  }

  [data-color="tone-oak"] {
    background-image: url("../img/palette/tone-oak.jpg");
  }

  [data-color="tone-teak"] {
    background-image: url("../img/palette/tone-teak.jpg");
  }

  [data-color="tone-walnut"] {
    background-image: url("../img/palette/tone-walnut.jpg");
  }

  [data-color="tone-oregon"] {
    background-image: url("../img/palette/tone-oregon.jpg");
  }

  [data-color="tone-beech"] {
    background-image: url("../img/palette/tone-beech.jpg");
  }

  [data-color="tone-white"] {
    background-image: url("../img/palette/tone-white.jpg");
  }

  [data-color="tone-pine"] {
    background-image: url("../img/palette/tone-pine.jpg");
  }

  [data-color="tone-rosewood"] {
    background-image: url("../img/palette/tone-rosewood.jpg");
  }

  [data-color="tone-red-tree"] {
    background-image: url("../img/palette/tone-red-tree.jpg");
  }

  [data-color="tone-rowan"] {
    background-image: url("../img/palette/tone-rowan.jpg");
  }

[data-color="tone-marigold"] {
  background-image: url("../img/palette/tone-marigold.jpg");
}


[data-color="em-teak"] {
  background-image: url("../img/palette/em-teak.jpg");
}

[data-color="em-walnut"] {
  background-image: url("../img/palette/em-walnut.jpg");
}

[data-color="em-oregon"] {
  background-image: url("../img/palette/em-oregon.jpg");
}

[data-color="em-pine"] {
  background-image: url("../img/palette/em-pine.jpg");
}

[data-color="em-rosewood"] {
  background-image: url("../img/palette/em-rosewood.jpg");
}

[data-color="em-red-tree"] {
  background-image: url("../img/palette/em-red-tree.jpg");
}

[data-color="em-rowan"] {
  background-image: url("../img/palette/em-rowan.jpg");
}

[data-color="em-mahogany"] {
  background-image: url("../img/palette/em-mahogany.jpg");
}

[data-color="em-marigold"] {
  background-image: url("../img/palette/em-marigold.jpg");
}


[data-color="az-colorless"] {
  background-image: url("../img/palette/az-colorless.jpg");
}

[data-color="az-oak"] {
  background-image: url("../img/palette/az-oak.jpg");
}

[data-color="az-teak"] {
  background-image: url("../img/palette/az-teak.jpg");
}

[data-color="az-oregon"] {
  background-image: url("../img/palette/az-oregon.jpg");
}

[data-color="az-white"] {
  background-image: url("../img/palette/az-white.jpg");
}

[data-color="az-pine"] {
  background-image: url("../img/palette/az-pine.jpg");
}

[data-color="az-rosewood"] {
  background-image: url("../img/palette/az-rosewood.jpg");
}

[data-color="az-mahogany"] {
  background-image: url("../img/palette/az-mahogany.jpg");
}

[data-color="az-larch"] {
  background-image: url("../img/palette/az-larch.jpg");
}

[data-color="az-rosewood"] {
  background-image: url("../img/palette/az-rosewood.jpg");
}

[data-color="az-marigold"] {
  background-image: url("../img/palette/az-marigold.jpg");
}

[data-color="mor-oak"] {
  background-image: url("../img/palette/mor-oak.jpg");
}

[data-color="mor-walnut"] {
  background-image: url("../img/palette/mor-walnut.jpg");
}

[data-color="mor-oregon"] {
  background-image: url("../img/palette/mor-oregon.jpg");
}

[data-color="mor-pine"] {
  background-image: url("../img/palette/mor-pine.jpg");
}
[data-color="mor-red-tree"] {
  background-image: url("../img/palette/mor-red-tree.jpg");
}

[data-color="mor-mahogany"] {
  background-image: url("../img/palette/mor-mahogany.jpg");
}

[data-color="mor-ebony"] {
  background-image: url("../img/palette/mor-ebony.jpg");
}

[data-color="mor-light-oak"] {
  background-image: url("../img/palette/mor-light-oak.jpg");
}

[data-color="mor-mocha"] {
  background-image: url("../img/palette/mor-mocha.jpg");
}

[data-color="lak-gloss"] {
  background-image: url("../img/palette/lak-gloss.jpg");
}

[data-color="lak-matte"] {
  background-image: url("../img/palette/lak-matte.jpg");
}

[data-color="lak-semi-matte"] {
  background-image: url("../img/palette/lak-semi-matte.jpg");
}

@media (max-width: $tablet-sm) {
  .palette {
    height: unset;
    width: unset;
  }

  .palette-item {
    height: 5rem;
    width: 5rem;

    &.active::before {
      width: 6rem;
      height: 6rem;
    }
  }

  .palette-text {
    font-size: 1.5rem;
  }
}

@media (max-width: $mobile) {
  .palette {
    padding: 1rem 2rem;
    gap: 1rem;
  }

  .palette-item {
    width: 3.5rem;
    height: 3.5rem;

    &.active::before {
      width: 4.5rem;
      height: 4.5rem;
    }
  }

  .palette:after {
    @include center-absolute;
    height: 2rem;
    width: 2rem;
    top: unset;
    bottom: -1rem;
  }
}