.btn {
  position: relative;
  display: flex;
  align-items: center;
  justify-content: center;
  border-radius: 10px;
  border: none;
  // padding: pxr(20) pxr(55);
  cursor: pointer;
  max-width: pxr(350);
  text-decoration: none;
  transition: all 0.3s;
  background: var(--btn-main-color);
  color: var(--text-main-color-additional);
}
