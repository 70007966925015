@font-face {
  font-family: "Roboto";
  src: local("Roboto"), local("Roboto-Regular"),
    url("../fonts/Roboto-Regular.woff") format("woff");
  font-weight: 400;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Medium"), local("Roboto-Medium"),
    url("../fonts/Roboto-Medium.woff") format("woff");
  font-weight: 500;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Bold"), local("Roboto-Bold"),
    url("../fonts/Roboto-Bold.woff") format("woff");
  font-weight: 700;
  font-style: normal;
}

@font-face {
  font-family: "Roboto";
  src: local("Roboto Black"), local("Roboto-Black"),
    url("../fonts/Roboto-Black.woff") format("woff");
  font-weight: 900;
  font-style: normal;
}
