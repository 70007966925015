@mixin flex-center {
  display: flex;
  justify-content: center;
  align-items: center;
}

@mixin zero {
  margin: 0;
  padding: 0;
}

@mixin lists {
  @include zero;
  list-style: none;
}

@mixin center-absolute {
  left: 0;
  right: 0;
  margin: 0 auto;
}
