.section-logos {
  margin-top: pxr(90);
}

/*
a - лого эмпилс
b - текст
c - логотипы
*/

.grid-logos {
  display: grid;
  grid-template-areas: "a b" "- c ";
}

.logos-logo {
  width: pxr(240);
  grid-area: a;
}

.logos-text {
  margin-left: pxr(48);
  font-weight: 400;
  font-size: pxr(16);
  line-height: pxr(20);
  color: #878787;
  grid-area: b;
}

.logos-brands {
  margin-left: pxr(48);
  display: grid;
  grid-template-columns: repeat(4, 1fr);
  margin-top: pxr(78);
  gap: pxr(50);
  grid-area: c;
}

.logo-main {
  display: block;
  width: 100%;
  height: 100%;
  background: url("../icons/logo-empils.svg") center center / contain no-repeat;
  transition: $transition;
}

.logos-brand {
  width: pxr(100);
  height: pxr(60);
  transition: $transition;
}

.logo-main:hover,
.logos-brand:hover {
  opacity: 0.5;
}

.logo-ag {
  background: url("../icons/brands/logo-ag.svg") center center / contain
  no-repeat;
  width: pxr(45);
}

.logo-newtone {
  background: url("../icons/brands/logo-newtone.svg") center center / contain
  no-repeat;
  width: pxr(108);
}

.logo-profiwood {
  background: url("../icons/brands/logo-profiwood.svg") center center / contain
  no-repeat;
  width: pxr(106);
}

.logo-titan {
  background: url("../icons/brands/logo-titan.svg") center center / contain
  no-repeat;
  white-space: pxr(59);
}

.logo-rassvet {
  background: url("../icons/brands/logo-rassvet.svg") center center / contain
  no-repeat;
  width: pxr(114);
}

.logo-oreol {
  background: url("../icons/brands/logo-oreol.svg") center center / contain
  no-repeat;
  width: pxr(106);
}

.logo-discont {
  background: url("../icons/brands/logo-discont.svg") center center / contain
  no-repeat;
  width: pxr(104);
}

.logo-e {
  background: url("../icons/brands/logo-e.svg") center center / contain
  no-repeat;
  width: pxr(52);
}

.logo-simplydyed {
  background: url("../icons/brands/logo-simplydyed.svg") center center / contain
  no-repeat;
  width: pxr(106);
}

@media (max-width: $tablet-sm) {
  .grid-logos {
    display: flex;
    flex-direction: column;
  }

  .logos-logo {
    height: 7rem;
    margin-bottom: 3.8rem;
  }

  .logos-text {
    margin-left: 0;
  }

  .logos-brand {
    margin: 0 auto;
  }

  .logos-brands {
    margin-left: 0;
    margin-top: 4rem;
    grid-template-columns: repeat(2, 1fr);
  }
}

@media (max-width: $mobile) {
  .section-logos {
    margin-top: 5rem;
  }

  .logos-logo {
    margin: 0 auto 2rem auto;
  }

  .logos-brands {
    gap: 1.5rem;
  }
}