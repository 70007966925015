.to-top {
  display: none;
  position: fixed;
  bottom: 40px;
  right: 40px;
  justify-content: center;
  align-items: center;
  border-radius: 50%;
  width: pxr(45);
  height: pxr(45);
  background: var(--main-color) url('../icons/arrow-up.svg') no-repeat center center;
  background-size: 30%;
  z-index: 30;
  box-shadow: 4px 4px 8px 0 rgba(34, 60, 80, 0.2);
}