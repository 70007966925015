*,
*::before,
*::after {
  box-sizing: inherit;
}

html {
  font-size: $percent_rem * 100%; // теперь мы можем использовать ремы, 1 рем равен 10 пикселям, то есть 1.6rem = 16px
  box-sizing: border-box;
}

body {
  margin: 0;
  padding: 0;
  font-family: "Roboto", sans-serif;
}

p {
  @include zero;
}

a {
  color: var(--text-main-color-additional);
  transition: $transition;
}

h2,
h3 {
  @include zero;
}

.display-none {
  display: none;
}

.section {
  display: flex;
}

.container {
  width: $container-width;
  margin: 0 auto;
}

.container--relative {
  position: relative;
}

.button {
  cursor: pointer;
  transition: all 0.3s;
}

.anchor {
  display: block;
  position: relative;
  top: -250px;
  visibility: hidden;
}

.bullet-list {
  margin: 0;
  padding: 0;

  li {
    display: flex;
    gap: 0.5rem;
    font-weight: 400;
    font-size: 2rem;
    line-height: 3rem;

    &::before {
      content: '';
      width: 1.6rem;
      height: 1.6rem;
      background: url("../icons/point-black.svg");
      background-repeat: no-repeat;
      background-size: contain;
      margin-top: 0.6rem;
      flex-shrink: 0;
    }
  }
}

.grecaptcha-badge {
  visibility: hidden;
}


@media (max-width: $tablet-sm) {
  .container {
    width: $container-width-tablet-sm;
  }
}

@media (max-width: $mobile) {
  .container {
    width: $container-width-mobile;
    padding: 0 1.5rem;
  }
}
