.section-main {
  display: flex;
  flex-direction: column;
  padding-top: 7vh;
  align-items: center;
  background: $background-linear-black,
    url("../img/img-main.jpg") 28% 60% / 135% no-repeat;
  height: 100vh;
}

.logo-box {
  display: flex;
  flex-direction: column;
  align-items: center;
}

.logo {
  width: 37vw;
  height: 25vh;
  position: relative;
  z-index: 1;
}

.logo-text {
  color: var(--text-main-color-additional);
  font-size: 5vh;
  font-weight: 900;
  line-height: 3.5rem;
  text-transform: uppercase;
  margin-top: 3vh;
  text-shadow: 2px 6px 8px rgba(0,0,0,0.53);
  z-index: 1;
}

.main-elements {
  display: flex;
  align-items: center;
  margin-top: auto;
  flex-direction: column;
  background-color: var(--main-color);
  background-image: url("../img/texture.svg");
  background-size: cover;
  width: 100%;
  height: 30vh;
  z-index: 1;
  position: relative;
}

.butterfly {
  width: 5rem;
  height: 5rem;
  position: absolute;
}

.flowers {
  width: 40vw;
  height: 42vh;
  position: absolute;
  background: url("../img/img-flowers.png") center top no-repeat;
  background-size: contain;
  z-index: 1;
  bottom: 15vh;
  left: -3vw;

  .butterfly {
    top: -2vh;
    left: 45%;
    background: url("../img/butterfly.gif") center top no-repeat;
    background-size: contain;
  }

  .butterfly-2 {
    top: 11vh;
    left: 30%;
    background: url("../img/butterfly_3.gif") center top no-repeat;
    background-size: contain;
    width: 5rem;
    height: 5rem;
    position: absolute;
  }
}

.logo-box {
  position: relative;

  .butterfly {
    right: 0;
    top: 1vh;
    transform: scale(-1, 1);
    background: url("../img/butterfly_2.gif") center top no-repeat;
    background-size: contain;
    z-index: 5;
  }
}

.butterflies-2 {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url("../img/butterflies_2.gif") center top no-repeat;
  background-size: cover;
  z-index: 0;
}


.butterflies {
  width: 100%;
  height: 100vh;
  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  background: url("../img/butterfiles.gif") center top no-repeat;
  background-size: cover;
  z-index: 0;
}

.card-elements {
  display: flex;
  flex-direction: row;
  justify-content: center;
  gap: 3rem;
  transform: translateY(-50%);
}

.main-card {
  position: relative;
  display: flex;
  align-self: center;
  border-radius: 1rem;
  background-color: var(--main-color-additional);
  min-height: 20vh;
  width: 30vw;
  overflow: hidden;
  transition: all 0.3s;
  text-decoration: none;

  &:hover {
    cursor: pointer;
    transform: scale(1.1);
  }
}

.main-card-img {
  flex-shrink: 0;
  flex-basis: 35%;
}

.main-card-img1 {
  background: url("../img/main-card-img1.jpg") center center/ cover no-repeat;
}
.main-card-img2 {
  background: url("../img/main-card-img2.jpg") center center/ cover no-repeat;
}
.main-card-img3 {
  background: url("../img/main-card-img3.jpg") center center/ cover no-repeat;
}

.main-card-text {
  color: var(--text-main-color-card);
  font-size: 2rem;
  font-weight: 600;
  line-height: normal;
  margin-left: 1.7rem;
  align-self: center;
}

.arrow {
  width: 1.5rem;
  height: 1.5rem;
  position: absolute;
  background: url("../icons/arrow.svg") center center/ contain no-repeat;
  bottom: 3rem;
  right: 2.4rem;
}

.link {
  display: flex;
  align-items: center;
  transform: translateY(-60%);
  color: var(--text-main-color-additional);
  font-size: 2.5rem;
  font-weight: 700;
  text-decoration-line: underline;
}

.arrow-down {
  position: absolute;
  background: url("../icons/arrow-down.svg") center center/ contain no-repeat;
  width: 6rem;
  height: 6rem;
  bottom: 0;
  left: 50%;
  transform: translateX(-50%);
}

.main-card-wrap {
  display: flex;
  align-items: center;
}

@media (max-width: $tablet-sm) {
  .section-main {
    background: $background-linear-black,
    url("../img/img-main.jpg") 28% 0 / 135% no-repeat;
  }

  .logo-text {
    margin-top: 2vh;
  }

  .main-card {
    flex-direction: column;
    justify-content: space-between;
    height: 20vh;
  }

  .main-card-img {
    height: 50%;
    flex-shrink: 0;
    order: 2;
    flex-basis: 50%;
  }

  .main-card-wrap {
    position: relative;
    height: 100%;
  }

  .main-card-text {
    color: #004544;
    font-size: pxr(18);
    line-height: 100%;
    width: 80%;
  }

  .logo-box .butterfly {
    top: 0;
  }
}

@media (max-width: $mobile) {
  .logo {
    width: 15rem;
    height: 4.6rem;
  }

  .logo-text {
    font-size: 1.5rem;
  }

  .section-main {
    background-size: cover;
    background-position: center;
    height: unset;
  }

  .logo-box {
    margin-bottom: 25rem;
  }

  .logo-box .butterfly {
    top: -3vh;
  }

  .main-elements {
    height: unset;

    & > .container {
      width: 100%;
    }
  }

  .card-elements {
    flex-direction: column;
    transform: translateY(-12%);
  }

  .main-card {
    width: 100%;
  }

  .arrow {
    position: static;
  }

  .main-card-wrap {
    justify-content: space-between;
    padding: 2.5rem;
  }

  .main-card-text {
    margin-left: 0;
  }

  .main-card-img {
    flex-basis: 50%;
  }

  .main-card-text {
    font-size: 1.5rem;
  }

  .arrow-down {
    display: none;
  }

  .link {
    font-size: 1.5rem;
    transform: none;
    margin: -2rem 0 2.5rem 0;
  }

  .flowers {
    width: 77vw;
    bottom: 20vh;
  }
}
