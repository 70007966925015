.section-form {
  margin-top: pxr(90);
}

.form-heading {
  font-weight: 700;
  font-size: pxr(70);
  color: var(--text-dye-header);
}

.form-description {
  margin-top: pxr(16);
  font-weight: 400;
  font-size: pxr(20);
  line-height: pxr(38);
  color: var(--text-btn-color);
}

.main-form {
  display: grid;
  margin-top: pxr(30);
  grid-template-columns: repeat(3, 1fr);
  gap: pxr(30);
}

.main-form input {
  width: pxr(230);
  height: pxr(50);
  padding: pxr(15);
  border: 1px solid #e3e8ec;
  border-radius: 10px;
  font-weight: 400;
  font-size: pxr(20);
  color: var(--input-text-color);
}

.main-form textarea {
  padding: pxr(15);
  width: 100%;
  border: 1px solid #e3e8ec;
  border-radius: 10px;
  font-weight: 400;
  font-size: pxr(20);
  color: var(--input-text-color);
  min-height: pxr(152);
  resize: none;
}

.form-question {
  grid-column: -2 / 1;
}

.main-form .btn {
  justify-self: end;
  grid-column: 3 / 3;
  grid-row: 2;
  justify-content: center;
  width: pxr(255);
  padding: pxr(15) 0;
  border-radius: 10px;
  height: pxr(50);
  margin-top: auto;

  font-weight: 400;
  font-size: pxr(20);
  line-height: pxr(20);
}

.main-form .btn:disabled {
  opacity: 0.5;
  cursor: default;
}

.form-result {
  margin-top: pxr(15);
  display: block;
  font-size: pxr(16);
}

.form-result-error {
  color: var(--error-color);
  text-align: center;
  display: block;
}

.form-result-success {
  color: var(--success-color);
}

@media (max-width: $tablet-sm) {
  .form-heading {
    font-size: 5rem;
  }

  .form-description {
    margin-top: 3rem;
  }

  .main-form {
    margin-top: 5rem;
  }

  .main-form input {
    width: 100%;
  }

  .main-form .btn {
    grid-row: 3;
  }

  .form-question {
    grid-column: -1/1;
  }
}

@media (max-width: $mobile) {
  .section-form {
    margin-top: 5rem;
  }

  .form-heading {
    font-size: 3rem;
    margin-bottom: 2rem;
    text-align: center;
  }

  .form-description {
    font-size: 1.6rem;
    line-height: 2.5rem;
    margin-top: 0;
  }

  .main-form {
    display: flex;
    flex-direction: column;
  }

  .main-form .btn {
    width: 100%;
  }
}
