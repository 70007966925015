.section-social {
  padding-top: pxr(80);
}

.social-items {
  display: flex;
  align-items: center;
  gap: pxr(30);

  a {
    display: flex;
    align-items: center;
    justify-content: center;
  }
}

.video-block {
  display: flex;
  align-items: center;
  background: var(--background-color-additional);
  border-radius: 10px;
  gap: pxr(45);
  padding: pxr(35) pxr(45);
  justify-content: space-between;
}

.youtube-link {
  width: pxr(169);
  height: pxr(40);
  background: url("../icons/social/youtube.svg") no-repeat center center;
  background-size: contain;
}

.rutube-link {
  width: pxr(45);
  height: pxr(45);
  background: url("../icons/social/rutube.svg") no-repeat center center;
  background-size: contain;
}

.social-block {
  display: flex;
  gap: pxr(32);

  a {
    @include flex-center;
    background-color: var(--background-color-additional);
    background-position: center center;
    border-radius: 50%;

    width: pxr(63);
    height: pxr(63);
    background-repeat: no-repeat;
  }

  .vk {
    background-image: url("../icons/social/vk.svg");
  }

  .ok {
    background-image: url("../icons/social/ok.svg");
  }
}

@media (max-width: $tablet-sm) {
  .video-block {
    padding: 3rem 4rem;
  }

  .youtube-link {
    width: 13rem;
  }

  .rutube-link {
    width: pxr(35);
  }

  .social-block {
    gap: 2.4rem;
  }

  .social-block a {
    width: pxr(47);
    height: pxr(47);
    background-size: 45%;
  }

  .social-block .ok {
    background-size: 30%;
  }
}

@media (max-width: $mobile) {
  .section-social {
    padding-top: 4rem;
  }

  .social-items {
    flex-direction: column;
  }
}