.footer {
  margin-top: pxr(90);
  background-color: var(--main-color);
}

.footer-contacts {
  text-decoration: none;
  color: var(--main-color-additional);
  transition: all 0.3s;
}

.footer-copyright {
  color: var(--main-color-additional);
}

.footer-content {
  display: flex;
  padding-top: pxr(39);
  font-weight: 700;
  font-size: pxr(20);
  line-height: pxr(20);
}

.footer-copyright {
  margin-left: auto;
}

.footer-recapcha {
  margin-top: pxr(20);
  padding-bottom: pxr(48);
  font-weight: 400;
  font-size: pxr(16);
  line-height: pxr(16);
  color: rgba(255, 255, 255, 0.5);
}

.footer-recapcha .link {
  color: inherit;
  cursor: pointer;
  transition: all 0.3s;
}

@media (max-width: $tablet-sm) {
  .footer-content {
    padding-top: 3rem;
  }

  .footer-recapcha {
    padding-bottom: 3rem;
  }
}

@media (max-width: $mobile) {
  .footer {
    margin-top: 5rem;
  }
}

