.section-item {
  position: relative;
  display: flex;
  flex-direction: column;
}

.section-item-header {
  background-attachment: fixed;
  background-repeat: no-repeat;
  height: $section-item-desktop-h;
  width: 100%;
  background-position: right 35%;
  background-size: cover;
  display: flex;
  align-items: center;
}

.dyes-container {
  background-image: url("../img/texture.svg");
  background-size: 100%;
  padding-bottom: 8.4rem;
}

.section-composition {
  .section-item-header {
    background-image: linear-gradient(180deg, #210E0A 0%, rgba(0, 0, 0, 0.00) 100%), url("../img/composition.jpg");
  }
}

.section-lacquer {
  .section-item-header {
    background-image: linear-gradient(180deg, #210E0A 0%, rgba(0, 0, 0, 0.00) 100%), url("../img/lacquer.jpg");
  }
}

.composition-img {
  max-height: 11.5rem;
  align-self: center;
}

.section-protective {
  .section-item-header {
    background-image: linear-gradient(180deg, #210E0A 0%, rgba(0, 0, 0, 0.00) 100%), url("../img/protective.jpg");
  }
}

.dye-section-header {
  color: var(--text-main-color-additional);
  padding-right: 20rem;
  font-size: 7rem;
  font-weight: 900;
  line-height: 7rem;
}

.dye-header {
  span {
    font-size: 2.4rem;
    font-weight: 600;
  }
}

//ВРЕМЕННО
.buttons-dye {
  overflow: hidden;
}

.covers-list {
  @include lists;
  margin-top: 20px;
}

.covers-point {
  display: flex;
  gap: 8px;
  align-items: center;
}

.covers-text {
  font-weight: 400;
  font-size: 2rem;
  line-height: 3rem;
}

.covers-point::before {
  content: '';
  width: 1.6rem;
  height: 1.6rem;
  background: url("../icons/point-black.svg");
  background-size: contain;
}

@media (max-width: $tablet-sm) {
  .dye-section-header {
    font-size: 5rem;
    line-height: 5rem;
    padding: 0;
  }

  .composition-img {
    max-height: 7.8rem;
    margin-bottom: 2rem;
  }
}

@media (max-width: $mobile) {
  .dye-section-header {
    font-size: 3.5rem;
    line-height: 3.5rem;
  }
}