.section-benefits {
  background-image: url("../img/texture.svg");
  background-size: cover;
  padding-bottom: 8.4rem;
  overflow: hidden;
}

.benefits-card {
  display: grid;
  grid-auto-columns: 1fr;
  //   grid-template-columns: 3fr 2fr;
  margin-top: 10rem;
  height: 49.4rem;
}

.benefit-card-description {
  display: flex;
  flex-direction: column;
  background: linear-gradient(135deg, rgb(9, 152, 146), rgb(0, 69, 68));
  padding-left: 9.5rem;
  border-radius: 1rem;
  grid-column: 1/9;
  grid-row: 1/2;
  padding-right: 15rem;
}

.card-description-header {
  display: flex;
  color: var(--text-main-color-additional);
  font-size: 4rem;
  font-weight: 900;
  line-height: 3.5rem;
  margin-top: 6.9rem;

  &:before {
    content: "";
    display: block;
    flex-shrink: 0;
  }

  &.effective {
    gap: 2.6rem;
    &:before {
      background: url("../icons/card-effective.svg") center center/contain
        no-repeat;
      width: 8rem;
    }
  }

  &.decorative {
    gap: 4.1rem;
    &:before {
      background: url("../icons/card-decorative.svg") center center/contain
        no-repeat;
      width: 9.5rem;
    }
  }

  &.complex {
    gap: 2.4rem;
    &:before {
      background: url("../icons/card-complex.svg") center center/contain
        no-repeat;
      width: 9rem;
    }
  }

  &.manufacturer {
    gap: 1.1rem;
    &:before {
      background: url("../icons/card-manufacturer.svg") center center/contain
        no-repeat;
      width: 9.5rem;
    }
  }
}

.card-description-text {
  color: var(--text-main-color-additional);
  font-size: 2.7rem;
  font-weight: 400;
  line-height: pxr(35);
  margin-top: pxr(32);

  &.complex {
    padding-right: 2rem;
  }

  &.manufacturer {
    padding-right: 2rem;
  }
}

.card-description-img {
  height: 90%;
  border-radius: 1rem;
  align-self: center;
  grid-column: 8/13;
  grid-row: 1/2;

  &.effective {
    background: url("../img/benefit-img-effective.jpg") center 80% / cover
      no-repeat;
  }

  &.decorative {
    background: url("../img/benefit-img-decorative.jpg") center 85% / cover
      no-repeat;
  }

  &.complex {
    background: url("../img/benefit-img-complex.jpg") left 30% / 115% no-repeat;
  }

  &.manufacturer {
    background: url("../img/benefit-img-manufacturer.jpg") 25% 20% / 175%
      no-repeat;
  }
}

.card-navigation {
  display: flex;
  margin-top: auto;
  margin-bottom: 3.2rem;
  gap: 2px;
}

.btn-card-navigation {
  background: none;
  border: none;
  cursor: pointer;

  span {
    display: inline-block;
    width: 1.9rem;
    height: 1.9rem;
    border: 1px solid var(--main-color-additional);
    border-radius: 50%;
    transition: $transition;
  }
}

.glide__bullet--active {
  span {
    background: var(--main-color-additional);
  }
}

.glide__bullets {
  position: absolute;
  left: pxr(95);
  bottom: pxr(40);
}

.glide__slide.glide__slide--active {
  opacity: 1;
  z-index: 1;
}

@media (max-width: $tablet-sm) {
  .benefits-card {
    display: flex;
    flex-direction: column;
    height: auto;
  }

  .card-description-header {
   margin-top: 4rem;
  }

  .card-description-img {
    height: pxr(400);
    width: pxr(634);
    position: relative;
    top: 4rem;
  }

  .benefit-card-description {
    order: 2;
    padding: 4rem 4rem 10rem 4rem;
    height: pxr(494);
  }

  .glide__bullets {
    bottom: 4rem;
    left: 3rem;
  }
}

@media (max-width: $mobile) {
  .benefits-card {
    margin-top: 4rem;
  }

  .benefit-card-description {
    height: 60rem;
  }

  .card-description-img {
    height: 24rem;
    width: 24rem;
  }

  .card-description-header {
    flex-direction: column;
    align-items: center;
    font-size: 2.5rem;
  }

  .card-description-header:before {
    width: 5.7rem;
    height: 6.5rem;
  }

  .benefit-card-description {
    padding: 2rem;
  }

  .card-description-text {
    font-size: 2rem;
    line-height: 2.5rem;
  }

  .glide__bullets {
    bottom: 1.5rem;
    left: 1.5rem;
  }
}