@charset "utf-8";
@import "../../node_modules/normalize.css/normalize.css";
@import "../../node_modules/animate.css/animate.min.css";
@import "../../node_modules/@glidejs/glide/src/assets/sass/glide.core";
@import "variables";
@import "mixins";
@import "functions";
@import "scaffolding";
@import "fonts";
@import "button";
@import "section-main";
@import "section-benefits";
@import "dye";
@import "palette";
@import "section-item";
@import "section-map";
@import "section-social";
@import "section-logos";
@import "section-form";
@import "footer";
@import "totop";
